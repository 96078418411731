import axios from "axios";

const token = localStorage.getItem("token");
// console.log("x-access-token", token);
// if (token) {
//   axios.defaults.headers["x-access-token"] = token;
// }

export const requestWrapper = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-access-token": token && token,
  },
});
