import axios from "axios";
import "../axios";

export const addCustomPlanApi = async (form) => {
  let data = await axios.post("/custom-plan-tarifaires", form);
  return data;
};

export const updateCustomPlanApi = async (id, form) => {
  let data = await axios.patch(`/custom-plan-tarifaires/${id}`, form);
  return data;
};
