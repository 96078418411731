import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "moment/locale/fr";
import {
  addCategorie,
  updateCategorie,
  getAllCategories,
  deleteCategorie,
} from "../../api/categoriesApi";
import { useDispatch, useSelector } from "react-redux";
import { categorySelector, setCategoryRedux } from "../../redux/store/appStore";
import { Button, Table, Tag } from "antd";

const GenericTableCategories = ({
  categories,
  setCategories,
  open,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const catSelector = useSelector(categorySelector);
  const [message, setMessage] = useState("");
  const [dataSelected, setDataSelected] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const cancelButtonRef = useRef(null);

  const [form, setForm] = useState({
    label: "",
    description: "",
    status: true,
  });

  const [formUpdate, setFormUpdate] = useState({
    label: dataSelected.label,
    description: dataSelected.description,
    status: dataSelected.status,
  });

  function handleChange(item, value) {
    setForm({
      ...form,
      [item]: value,
    });
  }

  function actifOrInactif(el) {
    if (el === "Actif") {
      return true;
    } else if (el === "Inactif") {
      return false;
    }
  }

  function handleChangeUpdate(item, value) {
    console.log("value", item, value);
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  //  GET ALL
  const allCategories = async () => {
    await getAllCategories()
      .then((res) => {
        setCategories(res.data.data);
        dispatch(setCategoryRedux(res.data.data));
      })
      .catch((err) => console.log("Error", err));
  };

  // CREATE
  const onCreate = async () => {
    setMessage("");
    await addCategorie(form)
      .then(() => {
        setMessage(
          <p className="bg-red-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-red-300">
            🚀 Catégorie créer avec succès !
          </p>
        );
        setTimeout(() => {
          setOpen(false);
          setMessage("");
        }, [1500]);

        allCategories();
      })
      .catch((err) => console.log("err", err));
  };

  // DELETE
  const onDelete = async () => {
    await deleteCategorie(dataSelected.id).then((res) => {
      allCategories();

      setMessage(
        <p className="bg-green-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-green-300">
          ⚠️ Catégorie supprimée avec succès !
        </p>
      );
      setTimeout(() => {
        setOpenDelete(false);
        setMessage("");
      }, [2000]);
    });
  };

  // UPDATE
  const onUpdate = async () => {
    await updateCategorie(dataSelected.id, formUpdate).then((res) => {
      console.log("res", res);
      allCategories();
      setMessage(
        <p className="bg-green-500 w-full text-sm text-center rounded-full p-2 shadow-lg shadow-green-300">
          ⚠️ Catégorie Mis a jour avec succès !
        </p>
      );
      setTimeout(() => {
        setOpenUpdate(false);
        setMessage("");
      }, [2000]);
    });
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "label",
      key: "label",
      render: (text, data) => (
        <div className="flex  flex-col justify-center">
          <p className="text-sm underline-offset-1">{text}</p>

          <Tag
            className="text-center w-24"
            color={data.status ? "green-inverse" : "red-inverse"}
          >
            {data.status ? "actif" : "inactif"}
          </Tag>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <p className="text-sm rounded-full text-left m-1 truncate">{text}</p>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      render: (text, data) => (
        <div>
          <Button
            icon={<FontAwesomeIcon icon={faEdit} />}
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => {
              setOpenUpdate(true);
              setMessage(null);
              setDataSelected({
                id: data.id,
                label: data.label,
                description: data.description,
                status: data.status,
              });
            }}
          />
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            type="primary"
            danger
            onClick={() => {
              setOpenDelete(true);
              setMessage(null);
              setDataSelected({
                id: data.id,
                label: data.label,
                description: data.description,
                status: data.status,
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Création",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => (
        <p className="text-sm text-left m-1 truncate">
          {moment(text).format("DD-MM-YYYY")}
        </p>
      ),
    },
  ];

  return (
    <div className="pt-5">
      <div className="shadow border-b border-gray-200 sm:rounded-lg overflow-auto">
        <Table
          columns={columns}
          dataSource={categories}
          rowKey="id"
          pagination={false}
          scroll={{ x: 400 }}
          className="min-w-full divide-y divide-gray-200"
        />
      </div>
      {/* Modals Create */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Ajout d'une nouvelle Catégorie
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                    <div className="mt-5 grid grid-cols-1 gap-4">
                      {/* Nom */}
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom de la Catégorie
                        </label>
                        <div className="mt-1">
                          <input
                            id="label"
                            name="label"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            // defaultValue={categories?.label}
                            onChange={(e) => {
                              handleChange("label", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {/* Description */}
                      <div className="">
                        <label
                          htmlFor="lastName"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="description"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            // defaultValue={categories?.description}
                            onChange={(e) => {
                              handleChange("description", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="mt-1 block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          // defaultValue={
                          //   categories.status === true ? "Actif" : "Inactif"
                          // }
                          onChange={(e) => {
                            handleChange(
                              "status",
                              actifOrInactif(e.target.value)
                            );
                            console.log(e.target.value);
                          }}
                        >
                          <option>Actif</option>
                          <option>Inactif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={onCreate}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modals Update */}
      <Transition.Root show={openUpdate} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenUpdate}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Modification d'une Catégorie
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                    <div className="mt-5 grid grid-cols-1 gap-4">
                      {/* Nom */}
                      <div className="">
                        <label
                          htmlFor="first-name"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Nom de la Catégorie
                        </label>
                        <div className="mt-1">
                          <input
                            id="label"
                            name="label"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            defaultValue={dataSelected?.label}
                            onChange={(e) => {
                              handleChangeUpdate("label", e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {/* Description */}
                      <div className="">
                        <label
                          htmlFor="lastName"
                          className="text-left block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <input
                            id="nom"
                            name="description"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-current focus:border-current sm:text-sm"
                            defaultValue={dataSelected?.description}
                            onChange={(e) => {
                              handleChangeUpdate("description", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Status
                        </label>
                        <select
                          id="status"
                          name="status"
                          className="mt-1 block w-full rounded-md border-gray-300 py-3 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          defaultValue={
                            dataSelected?.status === true ? "Actif" : "Inactif"
                          }
                          onChange={(e) => {
                            handleChangeUpdate(
                              "status",
                              actifOrInactif(e.target.value)
                            );
                            console.log(e.target.value);
                          }}
                        >
                          <option>Actif</option>
                          <option>Inactif</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onUpdate()}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpenUpdate(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modal Delete */}
      <Transition.Root show={openDelete} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenDelete}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center leading-6 font-medium text-gray-900 uppercase"
                    >
                      Supprimer la catégorie {dataSelected?.label} ?
                    </Dialog.Title>

                    <div className="mt-3 w-full border-t border-gray-400"></div>
                  </div>
                </div>
                {message ? (
                  message
                ) : (
                  <>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-hovers focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        onClick={() => onDelete()}
                      >
                        Valider
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpenDelete(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default GenericTableCategories;
