import React, { useState, useEffect, useMemo, useCallback } from "react";

import Loader from "../../components/shared/Loader";

import { useSelector } from "react-redux";
import Pagination from "../../components/shared/Pagination";
import { debounce } from "lodash";
import { FaDollarSign, FaEye, FaKey, FaLock, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  addClient,
  deleteClient,
  getAllClients,
  updateClient,
  updatePasswordClient,
} from "../../api/clientsApi";
import { Link, useHistory } from "react-router-dom";
import GenericModalDelete from "../../components/shared/GenericModalDelete";
import ModalAddClient from "../../components/Modals/ModalAddClient";
import ModalUpdateClient from "../../components/Modals/ModalUpdateClient";
import ModalActivate from "../../components/Modals/ModalActivate";
import ModalBlockClient from "../../components/Modals/ModalBlockClient";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalMessageInfo from "../../components/Modals/ModalMessageInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { getAllPublications } from "../../api/publicationApi";
import ModalConfirmation from "../../components/Modals/ModalConfirmation";
import { Button, Input, Table } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { clientsData } from "../../fakeDatas/clients";
import { max } from "moment";
import { useGetClients, useGetPublications } from "../../datas/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { GET_CLIENTS } from "../../datas/queryConstantes";
dayjs.locale("fr");

export default function ClientsPage() {
  let history = useHistory();
  const roles = useSelector((state) => state.auth?.user?.roles);
  const queryClient = useQueryClient();

  // Manage Modals States
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMessageInfo, setOpenMessageInfo] = useState(false);
  const [openBlockClient, setOpenBlockClient] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [newPassword, setNewPassword] = useState("123456");

  const [clients, setClients] = useState();

  const [loading, setLoading] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [message, setMessage] = useState("");
  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState(true);
  const [passwordTemp, setPasswordTemp] = useState("");
  const [infoUser, setInfoUser] = useState({
    id: "",
    name: "",
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: true,
  });

  const [formActive, setFormActive] = useState({
    nombre_de_jours: null,
    montant_paye: null,
    operateur: null,
    devise: "",
  });

  const [formUpdate, setFormUpdate] = useState({
    id: form.id,
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    phone: form.phone,
    password: form.password,
    status: form.status,
  });

  const [formClient, setFormClient] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: "",
  });

  const {
    data: clientsData,
    isLoading: loadingClients,
    isError: errorClients,
  } = useGetClients();

  const {
    data: moviesData,
    isLoading: loadingMovies,
    isError: errorMovies,
  } = useGetPublications();

  function createClient(item, value) {
    setFormClient({
      ...formClient,
      [item]: value,
    });
  }

  function genPassword() {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 4;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setPasswordTemp(password);
    console.log("password", password);
    setFormClient({ ...formClient, password: passwordTemp });
  }

  const getDataClients = async () => {
    setLoadingClient(true);
    await getAllClients()
      .then((res) => {
        setClients(res.data.data);
        setLoadingClient(false);
      })
      .catch((err) => {
        setLoadingClient(false);
        console.log("err", err);
      });
  };

  const onCreate = async () => {
    formClient.status = checked;

    await addClient(formClient)
      .then(() => {
        setMessage("Client ajouter avec succès");
        queryClient.invalidateQueries(GET_CLIENTS);

        setOpen(false);
        setLoading(false);
      })
      .then((err) => console.log("err", err));
  };

  const onblock = async () => {
    let _tempForm = null;
    if (form.status === true) {
      _tempForm = { ...form, status: false };
    } else {
      _tempForm = { ...form, status: true };
    }
    await updateClient(form.id, _tempForm).then((res) => {
      setMessage("Client Bloquer avec succès");
      getDataClients();
      setOpenBlockClient(false);
    });
  };

  useEffect(() => {
    clientsData && setClients(clientsData.data);
  }, [clientsData]);

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const onDelete = async () => {
    setLoading(true);
    await deleteClient(infoUser.id)
      .then(() => {
        setOpenDelete(false);
        queryClient.invalidateQueries(GET_CLIENTS);
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/clients");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  function handleUpdateClient(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  function handleActive(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  // Update Client
  const onUpdateClient = async () => {
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Update Password
  const ResetPassword = async () => {
    setLoading(true);

    await updatePasswordClient(form.id, { password: newPassword })
      .then((res) => {
        toast.success(<div> 🔐 Nouveau Mot de passe {newPassword}</div>, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setOpenConfirmation(false);

        getDataClients();

        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Activate Client for movie
  const onActivate = async () => {
    console.log("Activate ;-)");
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        queryClient.invalidateQueries(GET_CLIENTS);

        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  const filteredClients = useMemo(() => {
    return clients?.filter((val) => {
      if (val === "") {
        return val;
      } else if (
        val?.phone?.toLowerCase()?.includes(query?.toLowerCase()) ||
        val?.firstName?.toLowerCase()?.includes(query?.toLowerCase()) ||
        val?.lastName?.toLowerCase()?.includes(query?.toLowerCase()) ||
        val?.email?.toLowerCase()?.includes(query?.toLowerCase()) ||
        val?.identifiant?.toLowerCase()?.includes(query?.toLowerCase())
      ) {
        return val;
      }
      return null;
    });
  }, [query, clients]);

  const columns = [
    {
      title: "#ID",
      dataIndex: "identifiant",
      key: "identifiant",
      width: 150,
      sorter: (a, b) => a.identifiant.localeCompare(b.identifiant),
      render: (identifiant, user) => (
        <div>
          <div className="flex justify-center align-middle">
            <div className="uppercase text-sm bg-teal-500 text-white px-1.5 py-1 rounded-full">
              {identifiant}
            </div>
            <span className="inline-flex items-center p-1.5 ml-2 rounded-full text-xs font-medium bg-blue-500">
              <Link to={`/admin/detail-client/${user.id}`}>
                <FaEye className="text-white h-4 w-4" />
              </Link>
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Nom et Email",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (text, user) => (
        <div className="flex items-center">
          <div className="flex flex-col justify-center items-center">
            <Button
              icon={<FaDollarSign />}
              className="bg-sky-600 rounded-full text-white hover:bg-rose-600 border-0 "
              onClick={() => {
                setForm(user);
                !user.status ? setOpenMessageInfo(true) : setOpenActivate(true);
              }}
            />
            <div className="text-sm text-gray-900 text-center  mt-1">
              {user.status === true ? (
                <div className="bg-green-500 text-white rounded-full h-2.5 w-2.5 animate-pulse" />
              ) : (
                <div className="bg-red-500 text-white rounded-full h-2.5 w-2.5 animate-pulse" />
              )}
            </div>
          </div>
          <div
            className="ml-8 w-full"
            onClick={() => {
              setFormUpdate({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                status: user.status,
              });
              setOpenUpdate(true);
            }}
          >
            <div className="text-md capitalize font-medium text-gray-900 cursor-pointer">
              {user.firstName} {user.lastName}
            </div>
            {/* <div className="text-sm text-gray-500">{user.email}</div> */}
            {roles === "admin" || roles === "superviseur" ? (
              <div className="text-sm text-gray-500">
                {user.indicatif
                  ? `${user.indicatif} ${user.phone}`
                  : user.phone}
              </div>
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (text, user) => (
        <div>
          <Button
            icon={<FaDollarSign />}
            className="bg-teal-500 rounded-full text-white hover:bg-teal-600 border-0 "
            onClick={() => {
              setForm(user);
              !user.status ? setOpenMessageInfo(true) : setOpenActivate(true);
            }}
          />
          <Button
            icon={<FaKey />}
            className="ml-2 bg-blue-500 rounded-full text-white border-0"
            onClick={() => {
              setForm({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                address: user.address,
                phone: user.phone,
              });
              setOpenConfirmation(true);
            }}
          />
          <Button
            icon={<FaLock />}
            className="ml-2 bg-orange-500 rounded-full text-white border-0"
            onClick={() => {
              setForm({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                address: user.address,
                phone: user.phone,
                city: user.city,
                postalCode: user.postalCode,
                status: user.status,
              });
              setOpenBlockClient(true);
            }}
            disabled={roles !== "admin" && roles !== "superviseur"}
          />
          <Button
            icon={<FaTrash />}
            className="ml-2 bg-red-500 rounded-full text-white border-0"
            onClick={() => {
              setInfoUser({ id: user.id, name: user.firstName });
              setOpenDelete(true);
            }}
            disabled={roles !== "admin"}
          />
        </div>
      ),
    },
    {
      title: "Date de création",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (createdAt) => (
        <div className="text-sm text-gray-500">
          {dayjs(createdAt).format("DD MMM YYYY à HH:mm")}
        </div>
      ),
    },
  ];

  // Retarde L'appel lors de la saisie de la recherche !
  const debouncedSearch = useCallback(debounce(handleSearch, 1500), []);

  if (errorClients) {
    return <div>Erreur</div>;
  }

  return (
    <TemplateHeaderContainter title={`Clients`} dataCount={clients?.length}>
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          {/* <h1 className="mb-2 text-2xl font-semibold text-gray-900">
          Liste des Clients{" "}
          <span className="italic text-md font-light text-gray-500">
            ( {clients?.length} )
          </span>
        </h1> */}

          <button
            disabled={roles !== "admin" || roles !== "superviseur"}
            onClick={() => setOpen(true)}
            type="button"
            className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Ajouter un Client
          </button>
        </div>

        <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div>
            <label className="ml-3 text-gray-500" htmlFor="">
              Recherche :
            </label>
          </div>

          <Input
            className="border-5 pl-3 w-full lg:w-3/5 h-12 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
            type="text"
            placeholder="Saisir le code ou le nom du client ou numero de téléphone sans indicatif"
            onChange={debouncedSearch}
          />

          {loading || loadingClients ? (
            <div>
              <Loader />
              <div className="text-center text-gray-500 m-5">Chargement...</div>
            </div>
          ) : null}
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <Table
                    size="small"
                    loading={loading || loadingClients}
                    dataSource={filteredClients}
                    columns={columns}
                    scroll={{ x: 768 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal delete Client */}
        <GenericModalDelete
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          message={"Etes-vous sûre de vouloir supprimer ?"}
          data={infoUser.name}
          onDelete={onDelete}
        />

        {/* Modal add Client */}
        <ModalAddClient
          open={open}
          setOpen={setOpen}
          createClient={createClient}
          passwordTemp={passwordTemp}
          onCreate={onCreate}
          setChecked={setChecked}
        />

        {/* Modal Update Client */}
        <ModalUpdateClient
          formUpdate={formUpdate}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdateClient={handleUpdateClient}
          onUpdateClient={onUpdateClient}
        />

        {/* Modal Activate Client */}
        <ModalActivate
          formActive={formActive}
          open={openActivate}
          setOpen={setOpenActivate}
          handleActive={handleActive}
          onActivate={onActivate}
          data={form}
          loadingMovies={loadingMovies}
          movies={moviesData?.data}
        />

        {/* Modal Update Client */}
        <ModalMessageInfo
          open={openMessageInfo}
          setOpen={setOpenMessageInfo}
          data={form}
          picto={<FontAwesomeIcon icon={faBan} />}
        />

        <ModalConfirmation
          ResetPassword={ResetPassword}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          data={form}
          setNewPassword={setNewPassword}
          newPassword={newPassword}
        />

        {/* Modal Block Client */}
        <ModalBlockClient
          formActive={formActive}
          open={openBlockClient}
          setOpen={setOpenBlockClient}
          onblock={onblock}
          data={form}
        />
      </div>
      <ToastContainer />
    </TemplateHeaderContainter>
  );
}
