export const GET_PAIEMENTS = "GET_PAIEMENTS";
export const GET_PAIEMENT = "GET_PAIEMENT";

export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENT = "GET_CLIENT";
export const GET_ADMINS = "GET_ADMINS";
export const GET_ADMIN = "GET_ADMIN";
export const GET_CLIENT_TODAY = "GET_CLIENT_TODAY";

export const GET_PUBLICATIONS = "GET_PUBLICATIONS";

export const GET_PAIEMENTS_TODAY = "GET_PAIEMENTS_TODAY";
export const GET_PAIEMENTS_LAST_WEEK = "GET_PAIEMENTS_LAST_WEEK";
export const GET_SUBSCRIPTION_MONTH = "GET_SUBSCRIPTION_MONTH";

export const GET_PAIEMENTS_WEEK = "GET_PAIEMENTS_WEEK";

export const GET_STATS_PAIEMENTS = "GET_STATS_PAIEMENTS";
export const GET_STATS_HEADER = "GET_STATS_HEADER";
