import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faUsersLine,
  faClapperboard,
  faFilm,
  faCircleDollarToSlot,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Loader from "../../../components/shared/Loader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StatsComponent({
  stats,
  title,
  todayData,
  todayPaiement,
  currentUser,
  loading,
}) {
  function convertIcon(icon) {
    if (icon === "faUsersLine") {
      return faUsersLine;
    } else if (icon === "films") {
      return faClapperboard;
    } else if (icon === "series") {
      return faFilm;
    }
  }

  return (
    <div className="px-5">
      <h3 className="text-lg font-medium leading-6 text-gray-90 ml-5">
        {title}
      </h3>
      {!loading ? (
        <dl className="mt-3 grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
          {stats.map((item) => (
            <div
              key={item?.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md ">
                  {/* <item?.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                  <FontAwesomeIcon
                    icon={convertIcon(item?.icon)}
                    className={`text-xl mt-1 h-8 text-slate-600`}
                  />
                </div>
                <p className="ml-12 truncate text-md font-medium text-gray-700">
                  <span className="text-2xl mr-2 font-semibold text-orange-500">
                    {item?.stat}
                  </span>
                  {item?.name}{" "}
                </p>
              </dt>
              <dd className="ml-12 flex items-baseline pb-2 lg:pb-6 sm:pb-2">
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-3 sm:px-6">
                  <div className="text-sm">
                    <Link
                      to={`${item?.url}`}
                      className=" disabled  font-medium text-orange-600 hover:text-orange-500"
                    >
                      Voir Détails
                      <span className="sr-only"> {item?.name} stats</span>
                    </Link>
                  </div>
                </div>
              </dd>
            </div>
          ))}
          <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
            <dt>
              <p className="ml-12 truncate text-md font-medium text-gray-700">
                <span className="text-lg mr-2 font-light  text-orange-500">
                  Stats du {moment().format("DD/MM/YYYY")}
                </span>
              </p>
            </dt>
            <dt>
              <div className="absolute rounded-md ">
                {/* <item?.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                <FontAwesomeIcon
                  icon={faUsersLine}
                  className={`text-xl mt-1 h-8 text-slate-600`}
                />
              </div>
              <p className="ml-12 truncate text-md font-medium text-gray-700">
                <span className="text-2xl mr-2 font-semibold text-orange-500">
                  {todayData?.total}
                </span>
                inscrits{" "}
              </p>
            </dt>

            {currentUser.roles === "admin" && (
              <dt className="mt-2">
                <div className="absolute rounded-md ">
                  {/* <item?.icon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                  <FontAwesomeIcon
                    icon={faCircleDollarToSlot}
                    className={`text-xl mt-1 h-8 text-slate-600`}
                  />
                </div>
                <p className="ml-12 truncate text-md font-medium text-gray-700">
                  <span className="text-2xl mr-2 font-semibold text-orange-500">
                    {todayPaiement?.total}
                  </span>
                  Paiement(s){" "}
                </p>
              </dt>
            )}
            <dd className="ml-12 flex pb-2 lg:pb-6 sm:pb-2">
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-3 sm:px-6">
                <div className="text-sm">
                  {/* <Link
                  to={`${todayData?.url}`}
                  className=" disabled  font-medium text-orange-600 hover:text-orange-500"
                >
                  Voir Détails
                  <span className="sr-only"> {todayData?.name} stats</span>
                </Link> */}
                </div>
              </div>
            </dd>
          </div>
        </dl>
      ) : (
        <Loader />
      )}
    </div>
  );
}
