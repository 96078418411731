import axios from "axios";
import "../axios";

export const addUser = async (form) => {
  let data = await axios.post("/admins/create-admin", form);
  return data;
};

export const getAllUsers = async () => {
  let data = await axios.get("/admins");
  console.log("data Services :>> ", data);
  return data;
};

export const updateUser = async (id, form) => {
  let data = await axios.patch(`/admins/update-admin/${id}`, form);
  return data;
};

export const getUserInfo = async (id) => {
  let data = await axios.get(`/admins/${id}`);
  return data;
};
export const deleteUser = async (id) => {
  let data = await axios.delete(`/admins/${id}`);
  return data;
};

export const sendContactMail = async (form) => {
  let data = await axios.post("admins/send-mail", form);
  return data;
};

export const forgotPassword = async (email) => {
  let data = await axios.post("auth/admins/forgot-password", email);
  return data;
};

export const resetPassword = async (token, form) => {
  let data = await axios.post(`auth/admins/reset-password/${token}`, form);
  return data;
};

export const sendMailResume = async (partenaireId) => {
  let data = await axios.get("partenaires/stats/" + partenaireId);
  return data;
};
