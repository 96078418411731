import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import {
  AdminNavigation,
  PartenaireNavigation,
  ProducteurNavigation,
  SuperviseurNavigation,
} from "../../../utils/constants/navigations";
import { Link, useLocation } from "react-router-dom";
import { LOGO_WHITE } from "../../../shared/pictos";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../redux/store/authStore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBar({ sidebarOpen, setSidebarOpen }) {
  const user = useSelector(selectCurrentUser);
  const { pathname } = useLocation();
  const [sidebarMenu, setSideBarMenu] = useState([]);

  useEffect(() => {
    const getMenu = () => {
      if (user?.roles === "admin") {
        setSideBarMenu(AdminNavigation);
      } else if (user?.roles === "partenaire") {
        setSideBarMenu(PartenaireNavigation);
      } else if (user?.roles === "producteur") {
        setSideBarMenu(ProducteurNavigation);
      } else if (user?.roles === "superviseur") {
        setSideBarMenu(SuperviseurNavigation);
      }
    };
    getMenu();
  }, [user]);

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden "
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-8 w-auto" src={LOGO_WHITE} alt="Workflow" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                {sidebarMenu.length > 0 ? (
                  <nav className="px-2 space-y-1">
                    {sidebarMenu.map((item) => (
                      <Link
                        onClick={() => setSidebarOpen(false)}
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          pathname === item.href
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            pathname === item.href
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                ) : null}
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={`hidden md:flex md:w-48 md:flex-col md:fixed md:inset-y-0 `}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <Link
            to={"/admin"}
            className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900"
          >
            <img
              className="h-8 w-auto mx-auto"
              src={LOGO_WHITE}
              alt="Workflow "
            />
          </Link>
          <div className="flex-1 flex flex-col overflow-y-auto">
            {sidebarMenu.length > 0 ? (
              <nav className="flex-1 px-2 py-4 space-y-1">
                {sidebarMenu.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      pathname === item.href
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        pathname === item.href
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            ) : null}
          </div>
          <p className="mb-4 text-center text-sm text-teal-200 italic">
            {/* &copy; 2022 Lagui+ Cinéma  */}~ Version : v.1.6~
          </p>
        </div>
      </div>
    </>
  );
}
