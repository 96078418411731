import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  Dashboard,
  UserPage,
  Publications,
  DetailPublication,
  ClientsPage,
  DetailClient,
  PaiementPage,
  NotFound,
  UserDetailPage,
  InfosPage,
} from "../../pages/admin";
import DetailUser from "../../pages/admin/DetailUser";
import ActivatePage from "../../pages/admin/ActivatePage";

let widthSideBar = 48;
export default function PartenaireRoute() {
  return (
    <>
      <Switch>
        <Route exact component={PaiementPage} path="/admin/paiement" />
        <Route exact component={DetailUser} path="/admin/detail-user/:id" />

        {/* <Route exact component={UserPage} path="/admin/users" /> */}
        <Route exact component={ClientsPage} path="/admin/clients" />
        <Route exact component={ActivatePage} path="/admin/activate" />
        <Route exact component={DetailClient} path="/admin/detail-client/:id" />
        <Route exact component={Publications} path="/admin/contenus" />
        <Route
          exact
          component={DetailPublication}
          path="/admin/detail-publication/:id"
        />
        <Route exact component={UserDetailPage} path="/admin/detail-user" />
        <Route exact component={NotFound} path="/admin/not-found" />
        <Route exact component={InfosPage} path="/admin/infos" />
        <Route exact component={Dashboard} path="/" />
        <Route exact component={Dashboard} path="*" />
      </Switch>
    </>
  );
}
