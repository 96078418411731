import { message } from "antd";

export const handleError = (err, msg) => {
  // get error status code
  const status = err.response?.status;
  if (status === 403) {
    message.error("Error: Insufficient Permissions", 5);
  } else if (status === 500) {
    message.error(err.response.data, 5);
  } else if (typeof msg === "string") {
    message.error(t(msg), 5);
  } else if (typeof msg === "object" && msg[status]) {
    const messageToDisplay =
      typeof msg[status] === "object" ? msg[status] : t(msg[status]);
    message.error(messageToDisplay, 5);
  } else {
    message.error(
      getMessageFromError(err) || t("An error has occurred. Please try again."),
      5
    );
  }
  throw err;
};
