import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  GET_ADMINS,
  GET_CLIENT_TODAY,
  GET_CLIENTS,
  GET_PAIEMENTS,
  GET_PAIEMENTS_LAST_WEEK,
  GET_PAIEMENTS_TODAY,
  GET_PAIEMENTS_WEEK,
  GET_PUBLICATIONS,
  GET_STATS_HEADER,
  GET_STATS_PAIEMENTS,
  GET_SUBSCRIPTION_MONTH,
} from "./queryConstantes";
import {
  getAdminsApi,
  getClientsApi,
  getClientsTodayApi,
  getPaiementLastWeekApi,
  getPaiementsApi,
  getPaiementsTodayApi,
  getPaiementsWeekApi,
  getPublicationsApi,
  getStatsHeaderApi,
  getStatsPaiementApi,
  getSubscriptionMonthApi,
} from "./services";

export const useGetData = (data_id, params) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_DATA_QUERY_KEY, data_id, params],
    queryFn: () => getDataApi(data_id, params),
    enabled: !!data_id,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_DATA_QUERY_KEY, data_id, params],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetPaiements = (user_id) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_PAIEMENTS],
    queryFn: () => getPaiementsApi(user_id),
    enabled: !!user_id,
    staleTime: 1000 * 60 * 2, // 2 minutes
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_PAIEMENTS],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetClients = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_CLIENTS],
    queryFn: () => getClientsApi(),
    staleTime: 1000 * 60 * 2, // 2 minutes
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_CLIENTS],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetAdmins = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_ADMINS],
    queryFn: () => getAdminsApi(),
    staleTime: 1000 * 60 * 2, // 2 minutes
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_ADMINS],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetPublications = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_PUBLICATIONS],
    queryFn: () => getPublicationsApi(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_PUBLICATIONS],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetPaiementsToday = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_PAIEMENTS_TODAY],
    queryFn: () => getPaiementsTodayApi(),
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_PAIEMENTS_TODAY],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetClientsToday = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_CLIENT_TODAY],
    queryFn: () => getClientsTodayApi(),
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_CLIENT_TODAY],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetSubscriptionMonth = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_SUBSCRIPTION_MONTH],
    queryFn: () => getSubscriptionMonthApi(),
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_SUBSCRIPTION_MONTH],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetPaiementsWeek = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_PAIEMENTS_WEEK],
    queryFn: () => getPaiementsWeekApi(),
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_PAIEMENTS_WEEK],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetStatsPaiement = (user_id) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_STATS_PAIEMENTS],
    queryFn: () => getStatsPaiementApi(user_id),
    enabled: !!user_id,
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_STATS_PAIEMENTS],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetStatsHeader = (user_id) => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_STATS_HEADER],
    queryFn: () => getStatsHeaderApi(user_id),
    enabled: !!user_id,
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_STATS_HEADER],
    });
  };

  return {
    ...query,
    refetchData,
  };
};

export const useGetPaiementLastWeek = () => {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [GET_PAIEMENTS_LAST_WEEK],
    queryFn: () => getPaiementLastWeekApi(),
    staleTime: 1000 * 60 * 1,
  });

  const refetchData = () => {
    queryClient.invalidateQueries({
      queryKey: [GET_PAIEMENTS_LAST_WEEK],
    });
  };

  return {
    ...query,
    refetchData,
  };
};
