import React, { useState } from "react";
import { Form, Input } from "antd";
import ContractPageMd from "./ContractPageMd";

const ContractForm = () => {
  const [variables, setVariables] = useState({
    author_name: "",
    author_address: "Guinée",
    license_duration: 2,
    amount_CFA: 1000,
    amount_EUR: 4,
    amount_GNF: 15000,
  });

  const handleChange = (e) => {
    setVariables({
      ...variables,
      [e.target.name]: e.target.value,
    });
  };

  const handleNumberChange = (value, name) => {
    setVariables({
      ...variables,
      [name]: value,
    });
  };

  return (
    <div>
      <Form layout="vertical" className="grid lg:grid-cols-2 gap-x-4">
        <Form.Item label="Nom de la plateforme" name="platform_name">
          <Input
            className="w-full rounded-xl"
            name="platform_name"
            value={variables.platform_name}
            onChange={handleChange}
            placeholder="Nom de la plateforme"
          />
        </Form.Item>
        <Form.Item label="Nom de la société" name="company_name">
          <Input
            className="w-full rounded-xl"
            name="company_name"
            value={variables.company_name}
            onChange={handleChange}
            placeholder="Nom de la société"
          />
        </Form.Item>
        <Form.Item label="Adresse de la société" name="company_address">
          <Input
            className="w-full rounded-xl"
            name="company_address"
            value={variables.company_address}
            onChange={handleChange}
            placeholder="Adresse de la société"
          />
        </Form.Item>
        <Form.Item
          label="Numéro d'identification fiscale"
          name="company_tax_id"
        >
          <Input
            className="w-full rounded-xl"
            name="company_tax_id"
            value={variables.company_tax_id}
            onChange={handleChange}
            placeholder="Numéro d'identification fiscale"
          />
        </Form.Item>
        <Form.Item label="Nom de l'auteur" name="author_name">
          <Input
            className="w-full rounded-xl"
            name="author_name"
            value={variables.author_name}
            onChange={handleChange}
            placeholder="Nom de l'auteur"
          />
        </Form.Item>
        <Form.Item label="Adresse de l'auteur" name="author_address">
          <Input
            className="w-full rounded-xl"
            name="author_address"
            value={variables.author_address}
            onChange={handleChange}
            placeholder="Adresse de l'auteur"
          />
        </Form.Item>
        <Form.Item label="Durée de la licence (années)" name="license_duration">
          <Input
            className="w-full rounded-xl"
            Number
            name="license_duration"
            value={variables.license_duration}
            onChange={(value) => handleNumberChange(value, "license_duration")}
            placeholder="Durée de la licence (années)"
            min={0}
          />
        </Form.Item>
      </Form>

      <ContractPageMd variables={variables} />
    </div>
  );
};

export default ContractForm;
