import React from "react";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ContractPage from "./ContractPage";
import ContractPageMd from "./ContractPageMd";
import ContractForm from "./ContractForm";
import { selectCurrentUser } from "../../redux/store/authStore";
import { useSelector } from "react-redux";

const InfosPage = () => {
  const userconnected = useSelector(selectCurrentUser);
  console.log("userconnected", userconnected);

  return (
    <div>
      <TemplateHeaderContainter title={"Informations"}>
        <div className="max-w-7xl mx-auto px-4 sm:px-4 md:px-8 selection:bg-cyan-500 selection:text-white">
          <div className="font-light">
            Retrouvez dans cette section toutes les informations et bonnes
            pratiques pour la gestion et la compréhension de votre Panel. Nous
            publierons régulèrement des informations dans cette section.
            N'hésitez pas à consulter pour rester informer des nouveautés
          </div>

          <div className="mt-8 font-mono">
            <h2 className="text-xl font-semibold">
              Changements de la version 1.6
            </h2>
            <p>17 Aug 2024,</p>
            <ul className="list-disc text-xs list-inside mt-2 text-slate-600">
              <li>
                Ajout de React-Query pour une meilleure gestion des données et
                optimisation des performances et des appels vers la base de
                données.
              </li>
            </ul>
          </div>
          <div className="mt-8 font-mono">
            <h2 className="text-xl font-semibold">
              Changements de la version 1.5
            </h2>
            <p>13 Aug 2024,</p>
            <ul className="list-disc text-xs list-inside mt-2 text-slate-600">
              <li>
                Ajout d'une section activation pour activer les utilisateurs
                plus facilement.
              </li>
              <li>
                Optimisation du code, amélioration backend pour une meilleure
                performance.
              </li>
            </ul>
          </div>

          <div className="mt-8 font-mono">
            <h2 className="text-xl font-semibold">
              Changements de la version 1.4
            </h2>
            <p>09 Aug 2024,</p>
            <ul className="list-disc text-xs list-inside mt-2 text-slate-600">
              <li>
                Amélioration de l'interface utilisateur pour une navigation plus
                intuitive.
              </li>
              <li>
                Mise a jour des tableaux clients et des paiements.(plus intuitif
                et plus rapide)
              </li>
              <li>
                Correction de bugs mineurs dans le module de gestion des
                utilisateurs.
              </li>
              <li>
                Ajout de nouvelles fonctionnalités pour la gestion des
                paiements.
              </li>
              <li>
                Optimisation des performances pour un chargement plus rapide des
                pages.
              </li>
              <li>
                Mise à jour de la documentation pour refléter les nouvelles
                fonctionnalités.
              </li>
            </ul>
          </div>

          {userconnected.roles === "admin" && (
            <div className="mt-8">
              <ContractForm />
            </div>
          )}
        </div>
      </TemplateHeaderContainter>
    </div>
  );
};

export default InfosPage;
