import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Switch } from "@headlessui/react";
import CreatableSelect from "react-select/creatable";
import { getAllCategories } from "../../api/categoriesApi";
import {
  getPublicationInfo,
  updatePublication,
} from "../../api/publicationApi";
import { useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { getAllType } from "../../api/typesApi";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../redux/store/authStore";
import { getCountriesApi } from "../../api/utilsApi";
import { updateCustomPlanApi } from "../../api/custonPlanApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UpdatePublicationPage = () => {
  const user = useSelector(selectCurrentUser);

  const history = useHistory();
  let { id } = useParams();
  const [form, setForm] = useState({});
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [defaultCategory, setDefaultCategory] = useState(null);
  const [defaultType, setDefaultType] = useState("");
  const [message, setMessage] = useState("");
  const [categorieOption, setCategorieOption] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [enabled, setEnabled] = useState();
  const [langueOptions, setlangueOptions] = useState([
    { label: "Pular", value: "Pular" },
    { label: "Malinké", value: "Malinké" },
    { label: "Soussou", value: "Soussou" },
    { label: "Wolof", value: "Wolof" },
    { label: "Français", value: "Français" },
    { label: "Anglais", value: "Anglais" },
  ]);

  const [countries, setCountries] = useState([]);
  const [customPlans, setCustomPlans] = useState([]);

  const [qualityOption, setQualityOption] = useState([
    { label: "4K", value: "4K" },
    { label: "Full HD", value: "Full HD" },
    { label: "HD", value: "HD" },
    { label: "Low", value: "Low" },
  ]);

  const updateField = async (item, value) => {
    setForm({
      ...form,
      [item]: value,
    });
  };

  const getCountries = async () => {
    getCountriesApi().then((res) => {
      console.log("dataCountries", res.data.data);
      setCountries(res.data);
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const onInputChange = (item, value) => {
    let data;
    if (value.length == undefined) {
      data = value.value;
    } else {
      let temp = [];
      value.forEach((el) => {
        temp.push(el.value);
      });
      data = temp;
    }
    setForm({
      ...form,
      [item]: data,
    });
  };

  const onUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("formule", form.formule);
    formData.append("categoryId", form.categoryId);
    formData.append("description", form.description);
    formData.append("language", form.language);
    formData.append("quality", form.quality);
    formData.append("pantTarifaireId", form.planTarifaire.id);
    formData.append("days", form.days);
    formData.append("rate_cfa", form.rate_cfa);
    formData.append("rate_eur", form.rate_eur);
    formData.append("rate_gn", form.rate_gn);
    formData.append("release_date", form.release_date);
    formData.append("title", form.title);
    formData.append("availability", enabled);
    formData.append("trailer_link", form.trailer_link);

    if (typeof form.type === Array) {
      form.type.forEach((el) => {
        formData.append("types", el);
      });
    }
    if (file) {
      formData.append("file", file);
    }

    setLoading(true);
    await updatePublication(id, formData)
      .then(() => {
        customPlans.forEach((element) => {
          let data = {
            country: element.country,
            price: element.price,
          };
          setMessage("Mise à jour effectuée avec succès");
          updateCustomPlanApi(element.id, element)
            .then((res) => {
              console.log("id", id);

              setLoading(false);
              history.push(`/admin/detail-publication/11/${id}`);
            })
            .catch((error) => {
              setLoading(false);
              console.log("error", error);
            });
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
    history.push(`/admin/detail-publication/${id}`);
    // window.location.href = `${process.env.REACT_APP_CLIENT}/admin/detail-publication/${id}`;
  };

  const getData = async () => {
    let tmp = {};
    let cate = await getAllCategories();
    let type = await getAllType();
    let dType = "";
    let { data } = await getPublicationInfo(id);

    tmp = data.data;
    tmp.rate_gn = data.data.planTarifaire.rate_gn;
    tmp.rate_cfa = data.data.planTarifaire.rate_cfa;
    tmp.rate_eur = data.data.planTarifaire.rate_eur;
    tmp.days = data.data.planTarifaire.days;

    setForm(tmp);
    setEnabled(data.data.availability);
    let CatArray = [];
    let typeArray = [];
    cate.data.data.forEach((element, index) => {
      CatArray.push({ label: element.label, value: element.id });
      if (data.data.categoryId == element.id) {
        setDefaultCategory(index);
      }
    });
    type.data.data.forEach((element) => {
      typeArray.push({ label: element.label, value: element.id });
    });
    data.data.types.forEach((el) => {
      dType += " " + el.label;
    });

    setCustomPlans(data.data.customPlanTarifaires);
    // console.log("dType", dType);
    setDefaultType(dType);
    setCategorieOption(CatArray);
    setTypeOption(typeArray);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const addCustomPlan = (e) => {
    e.preventDefault();
    setCustomPlans([...customPlans, { country: "", rate: "" }]);
  };

  const updateCustomPlan = (index, field, value) => {
    const newCustomPlans = [...customPlans];
    newCustomPlans[index][field] = value;
    setCustomPlans(newCustomPlans);
  };

  console.log("customPlans", customPlans);

  return (
    <TemplateHeaderContainter title={`Mise à Jour`}>
      {/* {true ? (
        <Loader />
      ) : ( */}
      <div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="flex justify-center mb-2  ">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={enabled}
                  onChange={(value) => {
                    setEnabled(value);
                  }}
                  className={classNames(
                    !enabled ? "bg-red-600" : "bg-teal-500",
                    `relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 ${
                      !enabled ? "focus:ring-red-600" : "focus:ring-teal-600"
                    }  focus:ring-offset-2`
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      !enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3 text-sm">
                  {!enabled ? (
                    <span className="font-medium text-red-500">
                      Contenu Bloqué - Non Visible
                    </span>
                  ) : (
                    <span className="font-medium text-teal-500">
                      Contenu disponible
                    </span>
                  )}
                </Switch.Label>
              </Switch.Group>
            </div>
            <div className="lg:mx-10 mx-2 text-justify px-5 py-5  bg-white shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <form className="space-y-8 divide-y divide-gray-200">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Mise à jour du film{" "}
                        <span className="text-2xl font-bold text-blue-600  uppercase underline-offset-4 underline">
                          {" "}
                          {form.title}{" "}
                        </span>
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Merci de suivre les instructions par ligne.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Titre du contenu
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            value={form.title}
                            type="text"
                            name="title"
                            id="title"
                            onChange={(e) => {
                              updateField("title", e.target.value);
                            }}
                            className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="about"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description (Synopsis)
                        </label>
                        <div className="mt-1">
                          <textarea
                            value={form.description}
                            id="about"
                            name="about"
                            rows={5}
                            onChange={(e) => {
                              updateField("description", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                        <p className="mt-2 text-sm text-gray-500">
                          Information sur la description
                        </p>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="categorie"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Catégorie
                        </label>
                        <div className="mt-1">
                          <Select
                            defaultValue={categorieOption[defaultCategory]}
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            options={categorieOption}
                            onChange={(choice) =>
                              onInputChange("categoryId", choice)
                            }
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Type de contenus selectionne actuellement :
                          {/* <div className=" bg-green-100 text-black items-center px-2.5 py-0.5 rounded-lg text-xs font-medium capitalize">
                              {defaultType}
                            </div> */}
                        </label>
                        <div className="mt-1">
                          <Select
                            // isMulti
                            name="colors"
                            options={typeOption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            // defaultValue={}
                            onChange={(choice) => onInputChange("type", choice)}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="langue"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Langue sélectionner actuellement :
                          <span className=" bg-green-100 text-black  items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize">
                            {form.language}
                          </span>
                        </label>
                        <div className="mt-1">
                          <CreatableSelect
                            isMulti
                            options={langueOptions}
                            onChange={(choice) =>
                              onInputChange("language", choice)
                            }
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Qualité
                        </label>
                        <div className="mt-1">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={qualityOption[0]}
                            isClearable={true}
                            options={qualityOption}
                            onChange={(choice) =>
                              onInputChange("quality", choice)
                            }
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-6">
                        <label
                          htmlFor="link"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Lien de la bande annonce (Fournir Un lien youtube
                          Valide
                        </label>
                        <div className="mt-1">
                          <input
                            value={form.trailer_link}
                            type="text"
                            name="link"
                            id="link"
                            onChange={(e) => {
                              updateField("trailer_link", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                          <p className="text-xs italic text-center text-red-500">
                            {" "}
                            ex: https://www.youtube.com/embed/xxxxx)
                          </p>
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="cover-photo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cover photo (precisant la limite de taille)
                        </label>
                        <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-2 pb-2">
                          <label className="mt-2 flex items-center justify-center flex-col  border-dashed hover:bg-gray-100 hover:border-gray-300">
                            <div className="relative flex flex-col items-center justify-center ">
                              {file ? (
                                <div>
                                  <img
                                    style={{
                                      width: "600px",
                                      height: "300px",
                                    }}
                                    className="object-cover"
                                    src={URL.createObjectURL(file)}
                                    alt={file}
                                  />
                                </div>
                              ) : (
                                <>
                                  <img
                                    style={{
                                      width: "200px",
                                      height: "300px",
                                    }}
                                    src={form.image_banner}
                                    className=" text-gray-400 group-hover:text-gray-600 object-cover "
                                  />

                                  {/* <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                      cover image
                                    </p> */}
                                </>
                              )}
                            </div>
                            <input
                              type="file"
                              className="opacity-0"
                              accept="image/*"
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="date_s"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date de sortie
                        </label>
                        <div className="mt-1">
                          <input
                            value={moment(form.release_date).format(
                              "YYYY-MM-DD"
                            )}
                            type="date"
                            name="date_s"
                            id="date_s"
                            onChange={(e) => {
                              updateField("release_date", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="formule"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tarification
                        </label>
                        <div className="mt-1">
                          <select
                            // disabled={user.roles !== "admin"}
                            id="formule"
                            value={form.formule}
                            name="formule"
                            onChange={(e) => {
                              updateField("formule", e.target.value);
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          >
                            <option value={"payant"}>Payante</option>
                            <option value={"gratuit"}>Gratuit</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {form.formule === "payant" ? (
                      <div className="grid grid-cols-4 mt-8 gap-3">
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="prix_gn"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prix en GNF
                          </label>
                          <div className="mt-1">
                            <input
                              value={form.rate_gn}
                              type="number"
                              name="prix_gn"
                              id="prix_gn"
                              onChange={(e) => {
                                updateField("rate_gn", e.target.value);
                              }}
                              className="block shadow-teal-500/100 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="prix_cfa"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prix en CFA
                          </label>
                          <div className="mt-1">
                            <input
                              value={form.rate_cfa}
                              type="number"
                              name="prix_cfa"
                              id="prix_cfa"
                              onChange={(e) => {
                                updateField("rate_cfa", e.target.value);
                              }}
                              className="block shadow-teal-500/100  w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="prix_eur"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Prix en EUR
                          </label>
                          <div className="mt-1">
                            <input
                              value={form.rate_eur}
                              type="number"
                              name="prix_eur"
                              id="prix_eur"
                              onChange={(e) => {
                                updateField("rate_eur", e.target.value);
                              }}
                              className="block shadow-teal-500/100  w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-1">
                          <label
                            htmlFor="prix_eur"
                            className="block text-sm font-medium text-gray-700"
                          >
                            jours
                          </label>
                          <div className="mt-1">
                            <input
                              value={form?.days}
                              type="number"
                              name="days"
                              id="days"
                              onChange={(e) => {
                                updateField("days", e.target.value);
                              }}
                              className="block shadow-teal-500/100  w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="grid grid-cols-5 lg:grid-cols-6 gap-4 mt-4 ">
                  {form.formule === "payant"
                    ? customPlans.map((plan, index) => (
                        <>
                          <div className="col-span-2 lg:col-span-3">
                            <label
                              htmlFor="prix_gn"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Pays
                            </label>
                            <div className="mt-1">
                              <select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                value={plan.country}
                                onChange={(e) => {
                                  updateCustomPlan(
                                    index,
                                    "country",
                                    e.target.value
                                  );
                                }}
                              >
                                {countries &&
                                  countries?.data?.map((country) => (
                                    <option
                                      key={country.name}
                                      value={country.name}
                                    >
                                      {country.flag + " " + country.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-span-2 lg:col-span-3">
                            <label
                              htmlFor=""
                              className="block text-sm font-medium text-gray-700"
                            >
                              Prix{" "}
                            </label>
                            <div className="mt-1">
                              <input
                                type="number"
                                name="price"
                                id="price"
                                value={plan.price}
                                onChange={(e) => {
                                  updateCustomPlan(
                                    index,
                                    "price",
                                    e.target.value
                                  );
                                }}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              customPlans.splice(index, 1);
                              setCustomPlans([...customPlans]);
                            }}
                            className="bg-red-500 mt-8 hover:bg-red-700 text-white font-bold h-8 justify-center items-center py-1 px-4 rounded"
                          >
                            X
                          </button>
                        </>
                      ))
                    : null}
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      onClick={() => {
                        console.log("Test");
                        history.push(`/admin/detail-publication/${form.id}`);
                      }}
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Annuler
                    </button>
                    <button
                      onClick={onUpdate}
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Enregistrer
                    </button>
                  </div>
                  {message && <p> {message}</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </TemplateHeaderContainter>
  );
};

export default UpdatePublicationPage;
