import { Disclosure } from "@headlessui/react";
import React from "react";
import { selectCurrentUser } from "../redux/store/authStore";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * This is a generic component used to keep Tabs style and behavior consistent
 * @typedef Props
 * @property value - ID of the current active tab
 * @property index - index of this tab
 * @property children - Content of the TabPanel.
 */

const TemplateHeaderContainter = (props) => {
  const { title, dataCount, children, description, color, id, ...other } =
    props;

  const currentUser = useSelector(selectCurrentUser);

  return (
    <div>
      <Disclosure
        as="div"
        className="relative overflow-hidden bg-sky-700 pb-32 lg:pb-36 "
      >
        {({ open }) => (
          <>
            <div
              aria-hidden="true"
              className={classNames(
                open ? "bottom-0" : "inset-y-0",
                "absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0"
              )}
            >
              <div className="absolute inset-0 flex">
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#0a527b" }}
                />
                <div
                  className="h-full w-1/2"
                  style={{ backgroundColor: "#065d8c" }}
                />
              </div>
              <div className="relative flex justify-center">
                <svg
                  className="flex-shrink-0"
                  width={1750}
                  height={308}
                  viewBox="0 0 1750 308"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M284.161 308H1465.84L875.001 182.413 284.161 308z"
                    fill="#0369a1"
                  />
                  <path
                    d="M1465.84 308L16.816 0H1750v308h-284.16z"
                    fill="#065d8c"
                  />
                  <path
                    d="M1733.19 0L284.161 308H0V0h1733.19z"
                    fill="#0a527b"
                  />
                  <path
                    d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z"
                    fill="#0a4f76"
                  />
                </svg>
              </div>
            </div>
            <header className="relative py-5">
              <div className=" max-w-7xl px-4 sm:px-6 lg:px-8 grid grid-cols-2">
                <div className="flex justify-center items-center">
                  <h1 className="lg:text-3xl text-lg font-light tracking-tight text-white">
                    {title} {dataCount && <span className="ml-2"> - </span>}
                    <span className="text-4xl italic ml-2 font-black">
                      {dataCount}
                    </span>
                  </h1>
                </div>
                <div className="justify-end mt-3">
                  <Link
                    to={`/admin/detail-user/${currentUser.id}`}
                    className="text-white  border-2 px-4 py-1 rounded-lg "
                  >
                    Mes informations
                  </Link>
                </div>

                <p className="text-white font-extralight italic lg:text-sm text-xs">
                  {description}
                </p>
              </div>
            </header>
          </>
        )}
      </Disclosure>

      <main className="relative -mt-36">
        <div className="mx-auto max-w-screen-2xl px-0  pb-6 sm:px-6 lg:px-4 lg:pb-16">
          <div
            className={`overflow-hidden rounded-lg bg-${
              color ? color : "white"
            }   shadow mx-2`}
          >
            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-1 pt-10 pb-20 min-h-full ">
              {children}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TemplateHeaderContainter;
