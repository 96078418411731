import React, { useState, useEffect, Fragment } from "react";
import {
  faCancel,
  faEdit,
  faSave,
  faVideo,
  faTrash,
  faHouseMedicalCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useParams, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { getPublicationInfo } from "../../api/publicationApi";
import Loader from "../../components/shared/Loader";
import { addEpisode, updateEpisode, deleteEpisode } from "../../api/episodeApi";
import { PencilIcon, EyeIcon, TrashIcon } from "@heroicons/react/outline";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalShowPublication from "./components/ModalShowPublication";
import ModalAddEpisode from "./components/ModalAddEpisode";
import ModalUpdateEpisode from "./components/ModalUpdateEpisode";
import GenericModalDelete from "../../components/shared/GenericModalDelete";

import { ToastContainer, toast } from "react-toastify";
import { getLastPartOfURL } from "../../utils/parseData";
import {
  separateThousandsMonetary,
  separateThousandsMonetaryEuros,
} from "../../utils/separateThousand";
import { useSelector } from "react-redux";

const detailPublication = () => {
  const history = useHistory();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const roles = useSelector((state) => state.auth.user.roles);
  const currentUser = useSelector((state) => state.auth);
  console.log("currentUser :>> ", currentUser.user.roles);

  const [openUpdate, setOpenUpdate] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [lastPart, setLastPart] = useState();
  const [lastPartMobile, setLastPartMobile] = useState();
  const [episodeId, setEpisodeId] = useState();
  const [publication, setPublication] = useState({});
  const [episode, setEpisode] = useState({});
  let { id } = useParams();
  const [formEpisode, setFormEpisode] = useState({
    publicationId: Number(id),
  });
  const [formEpisodeAdd, setFormEpisodeAdd] = useState({
    publicationId: Number(id),
    label: "",
    description: "",
    duration: "",
    video_link: "",
    video_link_for_mobile: "",
    order_number: "",
    status: true,
  });

  function updateField(item, value) {
    setFormEpisode({
      ...formEpisode,
      [item]: value,
    });
  }

  function addField(item, value) {
    setFormEpisodeAdd({
      ...formEpisodeAdd,
      [item]: value,
    });
  }

  const saveEpisode = async (id) => {
    if (formEpisode.order_number) {
      formEpisode.order_number = Number(formEpisode.order_number);
    }

    setLoading(true);
    await updateEpisode(id, formEpisode);

    getPublication();
    setOpenUpdate(false);
  };

  const addSaveEpisode = async () => {
    setLoading(true);

    await addEpisode(formEpisodeAdd);
    setOpenAdd(false);
    getPublication();
  };

  useEffect(() => {
    getPublication();
  }, []);

  const getPublication = async () => {
    await getPublicationInfo(id)
      .then((res) => {
        if (res.status === 200) {
          setPublication(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        history.push("/admin/not-found");
      });
  };

  const onDelete = async () => {
    try {
      await deleteEpisode(episode.id).then((res) => {
        // console.log("res :>> ", res);
        setOpenDelete(false);
        getPublication();
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <>
      <TemplateHeaderContainter title={`Détail film`}>
        {loading || publication === undefined ? (
          <Loader />
        ) : (
          <>
            <div className="grid grid-cols-2 px-5 -mt-5 py-2 mx-5 bg-white rounded-lg shadow-lg">
              <div className="flex col-span-2 mx-auto ">
                <>
                  <h1 className="text-2xl mb-3"> {publication.title}</h1>

                  <FontAwesomeIcon
                    disabled={roles !== "admin" || roles !== "superviseur"}
                    icon={faEdit}
                    className={`text-2xl ml-10 mt-1 h-5 text-orange-500 cursor-pointer`}
                    onClick={() =>
                      history.push(
                        `/admin/update-publication/${publication.id}`
                      )
                    }
                  />
                </>
              </div>

              <div className="mx-auto">
                {publication.availability ? (
                  <span className="bg-teal-500 px-1 text-white py-0.5 rounded-lg flex justify-center ">
                    Disponible
                  </span>
                ) : (
                  <span className="bg-red-500 px-1 text-white py-0.5 rounded-lg flex justify-center ">
                    Bloqué
                  </span>
                )}
              </div>

              <div className="col-span-2 mx-auto ">
                <div className="">
                  <div className="italic text-gray-500">
                    <p>
                      {publication?.category.label}, Laguiplus
                      <span className="ml-5">
                        {moment(publication.release_date).format("DD-MM-YYYY")}
                      </span>
                    </p>
                  </div>
                  <p className="text-justify font-light italic">
                    {publication.description}
                  </p>
                </div>
                <div>
                  <div className="mt-5">Plan Tarifaire</div>
                  <div className="m-1 grid grid-cols-2 lg:grid-cols-6">
                    <div className="col-span-1"> Guinée : </div>
                    <div className="col-span-1 bg-orange-400 text-gray-800 font-semibold rounded-lg px-2 text-center ">
                      {separateThousandsMonetary(
                        publication?.planTarifaire?.rate_gn
                      )}{" "}
                      GNF
                    </div>
                  </div>
                  <div className="m-1 grid grid-cols-2 lg:grid-cols-6">
                    <div className="col-span-1"> Zone CFA : </div>
                    <div className="col-span-1 bg-rose-400 text-gray-800 font-semibold rounded-lg px-2 text-center ">
                      {separateThousandsMonetary(
                        publication?.planTarifaire?.rate_cfa
                      )}{" "}
                      CFA
                    </div>
                  </div>
                  <div className="m-1 grid grid-cols-2 lg:grid-cols-6">
                    <div className="col-span-1"> Stripe / Paypal: </div>
                    <div className="col-span-1 bg-teal-400 text-gray-800 font-semibold  rounded-lg px-2 text-center ">
                      {separateThousandsMonetaryEuros(
                        publication?.planTarifaire?.rate_eur
                      )}{" "}
                      €{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2 mx-auto mt-5 ">
                <img
                  src={publication?.image_banner}
                  alt="image cover "
                  className="h-56  rounded-lg"
                />
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-lg mt-5 py-4 m-5">
              <p className="font-medium m-2 text-center ">
                {" "}
                Les Différents Plans tarifaires
              </p>
              {publication &&
                publication?.customPlanTarifaires.map((item, index) => (
                  <div key={index} className="px-5 py-0 mx-5 ">
                    <div className="grid lg:grid-cols-5 grid-cols-3 gap-1">
                      <p className="font-semibold ">{item?.country}</p>
                      {separateThousandsMonetary(item?.price)}{" "}
                      <p> {item?.devise}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="px-5 py-5 mx-5 mt-5 overflow-auto  bg-white rounded-lg shadow-lg">
              <p className="text-center text-2xl m-2"> Bande annonce </p>
              <iframe
                className=" mx-auto w-full lg:w-3/5"
                width="400"
                height="315"
                src={publication?.trailer_link}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="px-5 py-5 mx-5 mt-5 overflow-auto  bg-white">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={(e) => {
                  e.preventDefault();
                  setFormEpisode({
                    id: publication.id,
                    description: "",
                    duration: "",
                    label: "",
                    order_number: 0,
                    publicationId: id,
                    video_link: "",
                    video_link_for_mobile: "",
                  });
                  setOpenAdd(true);
                }}
              >
                Ajouter un épisode
              </button>

              <div className="mt-5 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300  ">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Rang
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Titre
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                            >
                              Durée (min)
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                            >
                              Edit
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {publication.episodes.map((ep) => (
                            <tr key={ep.id}>
                              <td className="whitespace-nowrap px-3 py-0 text-sm text-gray-500">
                                {ep.order_number}
                              </td>
                              <td className="whitespace-nowrap py-0 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {ep.label}
                              </td>
                              <td className="whitespace-nowrap px-3 py-0 text-sm text-gray-500 text-center">
                                {ep.duration}
                              </td>
                              <td className="whitespace-nowrap px-3 py-0 text-sm text-gray-500">
                                {ep.status ? (
                                  <div className=" w-full flex justify-center text-white rounded-full bg-green-500 ">
                                    actif
                                  </div>
                                ) : (
                                  <div className=" w-full flex justify-center text-white rounded-full bg-red-700 ">
                                    bloqué
                                  </div>
                                )}
                              </td>
                              <td className=" whitespace-nowrap py-0 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                                <button
                                  onClick={() => {
                                    setEpisode(ep);
                                    setOpenShow(true);
                                  }}
                                  type="button"
                                  className="m-2 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-cyan-600 hover:bg-cyan-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                                >
                                  <EyeIcon className="h-5 w-5" />
                                </button>
                                {currentUser.user.roles === "admin" && (
                                  <>
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setEpisode(ep);
                                        setFormEpisode(ep);
                                        setOpenUpdate(true);
                                      }}
                                      type="button"
                                      className="m-2 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-orange-600 hover:bg-orange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                                    >
                                      <PencilIcon className="h-5 w-5" />
                                    </button>

                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setEpisode(ep);
                                        // console.log("EP", ep);
                                        setOpenDelete(true);
                                      }}
                                      type="button"
                                      className="m-2 inline-flex items-center p-1.5 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    >
                                      <TrashIcon className="h-5 w-5" />
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <GenericModalDelete
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          message={""}
          data={null}
          onDelete={onDelete}
        />

        <ModalUpdateEpisode
          open={openUpdate}
          setOpen={setOpenUpdate}
          formEpisode={formEpisode}
          updateField={updateField}
          saveEpisode={saveEpisode}
        />

        <ModalAddEpisode
          open={openAdd}
          setOpen={setOpenAdd}
          formEpisode={formEpisode}
          addField={addField}
          addSaveEpisode={addSaveEpisode}
        />

        <ModalShowPublication
          open={openShow}
          setOpen={setOpenShow}
          episode={episode}
        />
      </TemplateHeaderContainter>
    </>
  );
};

export default detailPublication;
