import React, { useState } from "react";
import moment from "moment";
import ModalDetailPaiement from "../Modals/ModalDetailPaiement";
import { useStateManager } from "react-select";
import { Table, Tag } from "antd";

const PaiementTable = ({ paiements, loading }) => {
  const [openDetail, setOpenDetail] = useState(false);
  const [paiementSelected, setPaiementSelected] = useState({});

  let EURO = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
  });

  function color(value) {
    if (value === "Stripe") {
      return "bg-teal-400";
    } else if (value === "Paypal") {
      return "bg-blue-400";
    } else if (value === "Agent") {
      return "bg-orange-400";
    }
  }

  const getTagColor = (operator) => {
    switch (operator) {
      case "orange money":
        return "orange";
      case "Stripe":
        return "blue";
      case "Stripe-mobile":
        return "blue";
      case "Free":
        return "blue";
      case "Airtel":
        return "red";
      default:
        return "green";
    }
  };

  const uniqueTitles = [
    ...new Set(paiements && paiements.map((item) => item.publication.title)),
  ];

  const titleFilters = uniqueTitles.map((title) => ({
    text: title,
    value: title,
  }));

  const uniqueOperators = [...new Set(paiements.map((item) => item.operator))];

  const operatorFilters = uniqueOperators.map((operator) => ({
    text: operator,
    value: operator,
  }));

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (id, record) => (
        <div
          className={`w-20 text-center cursor-pointer flex justify-center text-white text-sm ${
            record.status ? "bg-teal-500" : "bg-red-500"
          } rounded-xl py-0.5`}
          onClick={() => {
            setPaiementSelected(record);
            setOpenDetail(true);
          }}
        >
          #{id}
        </div>
      ),
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (date) => moment(date).format("DD MMM YYYY"),
      align: "center",
    },
    {
      title: "Paiement",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (amount_paid, record) => (
        <div>
          {amount_paid} {record.devise}
          <span className="ml-2 italic font-light">
            - {record.day_active} jrs
          </span>
        </div>
      ),
      align: "center",
    },
    {
      title: "Film",
      dataIndex: ["publication", "title"],
      key: "film",
      filters: titleFilters,
      onFilter: (value, record) => record.publication.title.includes(value),
      render: (title) => <div className="text-sm text-gray-900">{title}</div>,
      align: "center",
    },
    {
      title: "Opérateur",
      dataIndex: "operator",
      key: "operator",
      onFilter: (value, record) => record.operator.includes(value),
      filters: operatorFilters,
      render: (operator) => (
        <Tag color={getTagColor(operator)} key={operator}>
          {operator}
        </Tag>
      ),
      align: "center",
    },
  ];

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={paiements ? paiements : []}
        scroll={{ x: 768 }}
      />

      {/* Modal Delete */}
      <ModalDetailPaiement
        data={paiementSelected}
        open={openDetail}
        setOpen={setOpenDetail}
      />
    </div>
  );
};

export default PaiementTable;
