import { requestWrapper } from "../config/requestConfig";
import { handleError } from "../utils/index";

export const getDataApi = (data_id, params) =>
  requestWrapper
    .get(`api/c13s/endpoint/${data_id}/data`, {
      ...params,
    })
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getPaiementsApi = (user_id) =>
  requestWrapper
    .get(`api/v1/paiements/paiement-admins/${user_id}`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getClientsApi = () =>
  requestWrapper
    .get(`api/v1/clients`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getAdminsApi = () =>
  requestWrapper
    .get(`api/v1/admins`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getPublicationsApi = () =>
  requestWrapper
    .get(`api/v1/publications`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

// a suppimer
export const getPaiementsTodayApi = () =>
  requestWrapper
    .get(`api/v1/paiements/today`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getClientsTodayApi = () =>
  requestWrapper
    .get(`api/v1/clients/today`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

// a delete
export const getSubscriptionMonthApi = () =>
  requestWrapper
    .get(`api/v1/clients/subscription-per-month`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

// a supprimer
export const getPaiementsWeekApi = () =>
  requestWrapper
    .get(`api/v1/paiements/paiements-per-week`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getStatsPaiementApi = (user_id) =>
  requestWrapper
    .get(`api/v1/paiements/stats/${user_id}`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getStatsHeaderApi = (user_id) =>
  requestWrapper
    .get(`api/v1/stats/dashbaord-header/${user_id}`)
    .then((response) => response.data)
    .catch((error) => handleError(error));

export const getPaiementLastWeekApi = () =>
  requestWrapper
    .get(`api/v1/paiements/paiements-last-week`)
    .then((response) => response.data)
    .catch((error) => handleError(error));
