import React, { useState, useEffect, useRef } from "react";
import { marked } from "marked";
import Mustache from "mustache";
import SignatureCanvas from "react-signature-canvas";

const ContractPageMd = ({ variables }) => {
  const [contract, setContract] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const sigCanvas = useRef({});

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  const saveSignature = () => {
    const signature = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    // Envoyer la signature au serveur ou traiter comme besoin
    console.log("Signature URL: ", signature);
    closeModal();
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      const response = await fetch("/contract/contract_template.md");
      const template = await response.text();
      // Variables à insérer dans le template

      // Remplir le template avec les données
      const filledTemplate = Mustache.render(template, variables);

      // Convertir le Markdown en HTML
      const html = marked(filledTemplate);

      // Mettre à jour l'état pour afficher le contrat
      setContract(html);
    };

    fetchTemplate();
  }, [variables]);

  return (
    <div>
      <div
        className="text-justify px-5 py-2 bg-white rounded-xl leading-7 font-light"
        dangerouslySetInnerHTML={{ __html: contract }}
      />

      <div className="bg-slate-50 lg:w-3/4 border-2 border-red-500 rounded-xl p-4 relative">
        <h3>Signature numérique</h3>
        <SignatureCanvas
          penColor="blue"
          canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          ref={sigCanvas}
        />
        <div className="absolute h-0.5 w-5/6  bottom-20 bg-black right-2" />
        <button onClick={clearSignature} className="text-red-500">
          Effacer
        </button>
      </div>

      <div className="flex gap-5">
        <button
          onClick={saveSignature}
          className="bg-teal-500 px-5 rounded-lg py-1 mt-5 text-white "
        >
          Signer et Soumettre
        </button>
        <button
          onClick={closeModal}
          className="bg-red-500 px-5 rounded-lg py-1 mt-5 text-white "
        >
          Annuler
        </button>
      </div>
    </div>
  );
};

export default ContractPageMd;
