import React, { useState, useEffect, useMemo, useCallback } from "react";

import Loader from "../../components/shared/Loader";

import { useSelector } from "react-redux";
import Pagination from "../../components/shared/Pagination";
import { debounce } from "lodash";
import { FaDollarSign, FaEye, FaKey, FaLock, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  addClient,
  deleteClient,
  getAllClientByUniqueId,
  getAllClients,
  updateClient,
  updatePasswordClient,
} from "../../api/clientsApi";
import { Link, useHistory } from "react-router-dom";
import GenericModalDelete from "../../components/shared/GenericModalDelete";
import ModalAddClient from "../../components/Modals/ModalAddClient";
import ModalUpdateClient from "../../components/Modals/ModalUpdateClient";
import ModalActivate from "../../components/Modals/ModalActivate";
import ModalBlockClient from "../../components/Modals/ModalBlockClient";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import ModalMessageInfo from "../../components/Modals/ModalMessageInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getAllPublications } from "../../api/publicationApi";
import ModalConfirmation from "../../components/Modals/ModalConfirmation";
import { Button, Input, Spin, Tag, message } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { clientsData } from "../../fakeDatas/clients";
import { IconBase } from "react-icons";
dayjs.locale("fr");

export default function ActivatePage() {
  let history = useHistory();
  const roles = useSelector((state) => state.auth?.user?.roles);

  // Manage Modals States
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMessageInfo, setOpenMessageInfo] = useState(false);
  const [openBlockClient, setOpenBlockClient] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [newPassword, setNewPassword] = useState("123456");

  const [clients, setClients] = useState();
  const [movies, setMovies] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [messageInfo, setMessageInfo] = useState("");
  const [query, setQuery] = useState("");
  const [checked, setChecked] = useState(true);
  const [passwordTemp, setPasswordTemp] = useState("");
  const [infoUser, setInfoUser] = useState({
    id: "",
    name: "",
  });
  const [onlyClient, setOnlyClient] = useState();
  const [paiementClient, setPaiementClient] = useState();

  const [currentPage, setCurrentPage] = useState(1);

  const [form, setForm] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: true,
  });

  const [formActive, setFormActive] = useState({
    nombre_de_jours: null,
    montant_paye: null,
    operateur: null,
    devise: "",
  });

  const [formUpdate, setFormUpdate] = useState({
    id: form.id,
    firstName: form.firstName,
    lastName: form.lastName,
    email: form.email,
    phone: form.phone,
    password: form.password,
    status: form.status,
  });

  const [formClient, setFormClient] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    status: "",
  });

  function createClient(item, value) {
    setFormClient({
      ...formClient,
      [item]: value,
    });
  }

  function genPassword() {
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let passwordLength = 4;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
      let randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setPasswordTemp(password);
    console.log("password", password);
    setFormClient({ ...formClient, password: passwordTemp });
  }

  // const getDataClients = async () => {
  //   setLoadingClient(true);
  //   await getAllClients()
  //     .then((res) => {
  //       setClients(res.data.data);
  //       setLoadingClient(false);
  //     })
  //     .catch((err) => {
  //       setLoadingClient(false);
  //       console.log("err", err);
  //     });
  // };

  const getDataMovies = async () => {
    setLoading(true);

    await getAllPublications()
      .then((res) => {
        setMovies(res.data.data);
        console.log(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const onCreate = async () => {
    formClient.status = checked;

    await addClient(formClient)
      .then((res) => {
        setMessageInfo("Client ajouter avec succès");
        getDataClients();
        setOpen(false);
        setLoading(false);
      })
      .then((err) => console.log("err", err));
  };

  const onblock = async () => {
    let _tempForm = null;
    if (form.status === true) {
      _tempForm = { ...form, status: false };
    } else {
      _tempForm = { ...form, status: true };
    }
    await updateClient(form.id, _tempForm).then((res) => {
      setMessageInfo("Client Bloquer avec succès");
      getDataClients();
      setOpenBlockClient(false);
    });
  };

  useEffect(() => {
    // getDataClients();
    getDataMovies();
  }, []);

  function handleSearch(event) {
    setQuery(event.target.value);
  }

  const onDelete = async () => {
    setLoading(true);
    await deleteClient(infoUser.id)
      .then(() => {
        setOpenDelete(false);
        getDataClients();
        setTimeout(() => {
          setLoading(false);
          history.push("/admin/clients");
        }, 2000);
      })
      .catch((err) => console.log("err", err));
  };

  function handleUpdateClient(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  function handleActive(item, value) {
    setFormUpdate({
      ...formUpdate,
      [item]: value,
    });
  }

  // Update Client
  const onUpdateClient = async () => {
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Update Password
  const ResetPassword = async () => {
    setLoading(true);

    await updatePasswordClient(form.id, { password: newPassword })
      .then((res) => {
        toast.success(<div> 🔐 Nouveau Mot de passe {newPassword}</div>, {
          position: "top-center",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setOpenConfirmation(false);

        getDataClients();

        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  // Activate Client for movie
  const onActivate = async () => {
    console.log("Activate ;-)");
    setLoading(true);
    await updateClient(formUpdate.id, formUpdate)
      .then((res) => {
        setOpenUpdate(false);
        getData();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        console.log("res", res);
      })
      .catch((err) => console.log("err", err));
  };

  const getClientByUniqueId = async (uniqueId) => {
    setLoadingClient(true);
    setOnlyClient(null);
    await getAllClientByUniqueId(uniqueId)
      .then((res) => {
        setOnlyClient(res.data.data);
        setPaiementClient(res.data.paiement);
        setLoadingClient(false);
        console.log("res.data.status", res.data.status);

        if (res.data.status === "error") {
          message.error(res.data.message, 5);
        } else {
          message.success(
            `le client ${res.data.data.firstName} a été trouvé`,
            5
          );
        }
      })
      .catch((err) => {
        setLoadingClient(false);
        console.log("err", err);
      });
  };

  console.log("onlyClient", onlyClient);

  return (
    <TemplateHeaderContainter title={`Activation Client`}>
      <div className="">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex items-center gap-2">
          <FontAwesomeIcon
            className="text-sky-500 text-xl"
            icon={faInfoCircle}
          />

          <div className="text-gray-500 text-md font-light italic ">
            Pour activer un client, veuillez entrer le code unique de 5
            caractères qui lui a été attribué et taper "entrer du clavier ou le
            boutton Valider"
          </div>
        </div>

        <div className="mt-5 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="flex justify-center items-center ">
            <Input
              className="border-5 mx-auto pl-3 lg:w-1/4 md:w-1/2   text-2xl font-bold text-sky-800 uppercase text-center h-14 rounded-lg shadow-md mb-5 border-b-2 border-red-500"
              type="text"
              placeholder="code"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (query.length === 5) {
                    getClientByUniqueId(query.toLowerCase());
                  }
                }
              }}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              maxLength={5}
            />
          </div>
          <Spin spinning={loadingClient}>
            <button
              onClick={() => {
                getClientByUniqueId(query.toLowerCase());
              }}
              className="bg-blue-500 text-white h-12 px-3 py-2 mb-5 rounded-lg w-full lg:w-1/4 md:w-1/2 mx-auto flex justify-center"
            >
              Valider
            </button>
          </Spin>

          {onlyClient ? (
            <div className="mx-auto max-w-xl">
              <div className="mx-auto text-center mb-5">
                <div className="flex justify-center items-center">
                  <div className="text-slate-500 text-3xl font-extrabold uppercase italic ">
                    {onlyClient?.identifiant}
                  </div>
                  <div
                    className={`ml-5 h-5 w-5 rounded-full animate-pulse ${
                      onlyClient?.status ? "bg-teal-500" : "bg-red-500"
                    } `}
                  />
                </div>
              </div>
              <div className="grid sm:grid-cols-6">
                <div className="sm:col-span-2 lg:col-span-3 font-semibold text-gray-500">
                  identité :
                </div>
                <div className="col-span-3 text-sky-500 italic ml-5 capitalize">
                  {onlyClient?.firstName} {onlyClient?.lastName}
                </div>
                <div className="sm:col-span-2 lg:col-span-3  font-semibold text-gray-500">
                  Téléphone :
                </div>
                <div className="col-span-3 text-sky-500 italic ml-5 ">
                  {/* {onlyClient?.indicatif && "+" + onlyClient?.indicatif}{" "}
                  {onlyClient?.phone} */}
                  <a
                    href={`tel:${onlyClient?.indicatif}${onlyClient?.phone}`}
                    className="text-sky-500 underline"
                  >
                    {" "}
                    {onlyClient?.indicatif && "+" + onlyClient?.indicatif}{" "}
                    {onlyClient?.phone}
                  </a>
                </div>
                <div className="sm:col-span-2 lg:col-span-3  font-semibold text-gray-500">
                  Email :
                </div>
                <div className="col-span-3 text-sky-500 italic ml-5 ">
                  {onlyClient?.email}
                </div>
              </div>
              <button
                onClick={() => {
                  setForm(onlyClient);
                  !onlyClient.status
                    ? setOpenMessageInfo(true)
                    : setOpenActivate(true);
                }}
                className="bg-green-500 text-white px-3 py-2 rounded-lg flex justify-center mx-auto m-5 w-full md:w-1/2 lg:w-1/4"
              >
                Activer
              </button>
              <div className="flex mt-5 justify-center m-2 font-semibold text-gray-500 ">
                Derniers Paiements de {onlyClient?.firstName}
              </div>
              {!!paiementClient &&
                paiementClient.map((paiement) => (
                  <div className=" m-5" key={paiement.id}>
                    <div className="font-semibold text-gray-500">
                      Montant :
                      <span className=" text-sky-500 italic ml-5 ">
                        {paiement?.amount_paid} {paiement?.devise}
                      </span>
                    </div>
                    <div className="font-semibold text-gray-500">
                      Nombre de jours :{" "}
                      <span className=" text-sky-500 italic ml-5 ">
                        {paiement?.day_active}
                      </span>
                    </div>

                    <div className="  font-semibold text-gray-500">
                      Opérateur :
                      <span className=" text-sky-500 italic ml-5 ">
                        {paiement?.operator}
                      </span>
                    </div>
                    <div className="font-semibold text-gray-500">
                      Statut du paiement :
                      <Tag
                        color={`${paiement.status ? "green" : "red"}`}
                        className=" italic ml-5 uppercase text-lg "
                      >
                        {paiement?.status ? "Payé" : "Non Terminé"}
                      </Tag>
                    </div>
                  </div>
                ))}
            </div>
          ) : null}
        </div>

        {/* Modal delete Client */}
        <GenericModalDelete
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          message={"Etes-vous sûre de vouloir supprimer ?"}
          data={infoUser.name}
          onDelete={onDelete}
        />

        {/* Modal add Client */}
        <ModalAddClient
          open={open}
          setOpen={setOpen}
          createClient={createClient}
          passwordTemp={passwordTemp}
          onCreate={onCreate}
          setChecked={setChecked}
        />

        {/* Modal Update Client */}
        <ModalUpdateClient
          formUpdate={formUpdate}
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          handleUpdateClient={handleUpdateClient}
          onUpdateClient={onUpdateClient}
        />

        {/* Modal Activate Client */}
        <ModalActivate
          formActive={formActive}
          open={openActivate}
          setOpen={setOpenActivate}
          handleActive={handleActive}
          onActivate={onActivate}
          data={form}
          movies={movies}
        />

        {/* Modal Update Client */}
        <ModalMessageInfo
          open={openMessageInfo}
          setOpen={setOpenMessageInfo}
          data={form}
          picto={<FontAwesomeIcon icon={faBan} />}
        />

        <ModalConfirmation
          ResetPassword={ResetPassword}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          data={form}
          setNewPassword={setNewPassword}
          newPassword={newPassword}
        />

        {/* Modal Block Client */}
        <ModalBlockClient
          formActive={formActive}
          open={openBlockClient}
          setOpen={setOpenBlockClient}
          onblock={onblock}
          data={form}
        />
      </div>
      <ToastContainer />
    </TemplateHeaderContainter>
  );
}
