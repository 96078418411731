import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Dashboard,
  AddPublicationPage,
  Publications,
  DetailPublication,
  CategoriesPage,
  ClientsPage,
  DevisePage,
  DetailClient,
  SendLinkPage,
  UpdatePublicationPage,
  NotFound,
  GenderPage,
  PaiementPage,
  UserDetailPage,
  InfosPage,
} from "../../pages/admin";
import ActivatePage from "../../pages/admin/ActivatePage";

let widthSideBar = 48;
export default function SuperviseurRoute() {
  return (
    <>
      <Switch>
        <Route exact component={PaiementPage} path="/admin/paiement" />
        <Route exact component={ClientsPage} path="/admin/clients" />
        <Route exact component={DetailClient} path="/admin/detail-client/:id" />
        <Route exact component={Publications} path="/admin/contenus" />
        <Route
          exact
          component={DetailPublication}
          path="/admin/detail-publication/:id"
        />
        <Route
          exact
          component={UpdatePublicationPage}
          path="/admin/update-publication/:id"
        />
        <Route exact component={AddPublicationPage} path="/admin/add-contenu" />
        <Route exact component={CategoriesPage} path="/admin/categories" />
        <Route exact component={SendLinkPage} path="/admin/send-link" />
        <Route exact component={ActivatePage} path="/admin/activate" />
        <Route exact component={InfosPage} path="/admin/infos" />
        <Route exact component={GenderPage} path="/admin/genders" />
        <Route exact component={DevisePage} path="/admin/devise" />
        <Route exact component={UserDetailPage} path="/admin/detail-user" />
        <Route exact component={NotFound} path="/admin/not-found" />
        <Route exact component={Dashboard} path="/" />
        <Route exact component={Dashboard} path="*" />
      </Switch>
    </>
  );
}
