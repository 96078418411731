import axios from "axios";
import "../axios";

export const addPublication = async (form) => {
  let data = await axios.post("/publications/add-pub", form);
  return data;
};

export const getAllPublications = async () => {
  let data = await axios.get("/publications");
  return data;
};

export const getPublicationByAdmin = async (id) => {
  let data = await axios.get(`/publications/publications-by-admins/${id}`);
  return data;
};

export const updatePublication = async (id, form) => {
  let data = await axios.patch(`/publications/update-pub/${id}`, form);
  return data;
};

export const getPublicationInfo = async (id) => {
  try {
    let data = await axios.get(`/publications/publications-for-mobile/${id}`);
    return data;
  } catch (error) {
    return data;
  }
};
export const deletePublication = async (id) => {
  let data = await axios.delete(`/publications/${id}`);
  return data;
};
