import React, { useEffect, useMemo, useState } from "react";

import TitleComponent from "../../shared/TitleComponent";
import { faCartFlatbed, faVideo } from "@fortawesome/free-solid-svg-icons";
import GenericTableContenu from "../../components/TableComponent/GenericTableContenu";
import GenericButton from "../../components/shared/GenericButton";
import GenericLink from "../../components/shared/GenericLink";
import GenericTableCategories from "../../components/TableComponent/GenericTableCategories";

import { fakeCategories } from "../../fakeDatas/fakeDatas";
import { getAllCategories } from "../../api/categoriesApi";
import { useDispatch } from "react-redux";
import { setCategoryRedux } from "../../redux/store/appStore";
import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";

export default function CategoriesPage() {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState();
  const dispatch = useDispatch();

  const allCategories = async () => {
    await getAllCategories()
      .then((res) => {
        setCategories(res.data.data);
        dispatch(setCategoryRedux(res.data.data));
      })
      .catch((err) => console.log("Error", err));
  };

  useEffect(() => {
    allCategories();
  }, []);

  const addCategory = () => {};

  return (
    <TemplateHeaderContainter
      title={"Liste des catégories"}
      dataCount={categories?.length}
    >
      <div className="px-5">
        <button
          onClick={() => setOpen(true)}
          className="mt3 mb-3 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
        >
          Ajouter une catégorie
        </button>
        <div className="p-1">
          <div className=" grid lg:grid-cols-3 sm:grid-cols-1  mx-auto ">
            {/* Replace with your content */}
          </div>

          <GenericTableCategories
            categories={categories}
            setCategories={setCategories}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
    </TemplateHeaderContainter>
  );
}
