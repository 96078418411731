import React, { useState } from "react";

import TemplateHeaderContainter from "../../shared/TemplateHeaderContainter";
import { Bar, Line } from "react-chartjs-2";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import {
  getPaiementsLastWeek,
  getPaiementsStats,
  getPaiementsWeek,
} from "../../api/paiementsApi";
import { getClientsStats } from "../../api/clientsApi";
import { selectCurrentUser } from "../../redux/store/authStore";
import { useDispatch, useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const optionsBar = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

export default function StatPage() {
  const currentUser = useSelector(selectCurrentUser);

  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const [paiementStat, setPaiementStat] = useState([]);
  const [clientStat, setClientStat] = useState([]);
  const [paiementsWeekStat, setPaiementsWeekStat] = useState([]);
  const [lastWeekPaiement, setLastWeekPaiement] = useState([]);

  const dispatch = useDispatch();

  const chartHeight = window.innerWidth < 600 ? 250 : 150;

  const getStatsPerMonths = async () => {
    await getPaiementsStats()
      .then((res) => {
        setPaiementStat(res.data.data);
      })
      .catch((err) => console.log("err", err));

    await getClientsStats()
      .then((res) => {
        setClientStat(res.data.data);
      })
      .catch((err) => console.log("err", err));

    await getPaiementsWeek()
      .then((res) => {
        setPaiementsWeekStat(res.data.data);
      })
      .catch((err) => console.log("err", err));

    await getPaiementsLastWeek()
      .then((res) => {
        setLastWeekPaiement(res.data.data);
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    getStatsPerMonths();
  }, []);

  const labelPaiement = paiementStat?.map((data) => data.date);
  const labelClient = clientStat?.map((data) => data.date);
  const labelPaiementWeek = paiementsWeekStat?.map((data) => data.date);
  const labelPaiemenLastWeek = lastWeekPaiement?.map((data) => data.date);

  console.log("labelPaiement", labelPaiement);

  const dataPointsPaiement = paiementStat?.map((data) => data.count);
  const dataPointsClient = clientStat?.map((data) => data.count);
  const dataPointsPaiementWeek = paiementsWeekStat?.map((data) => data.count);
  const dataPointsPaiementLastWeek = lastWeekPaiement?.map(
    (data) => data.count
  );

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        // text: "Statistique des paiements tout confondus",
      },
      scales: {
        y: {
          type: "linear",
          display: true,
          position: "left",
          min: 0, // Set the minimum value for the y-axis
          max: 120, // Set the maximum value for the y-axis
          ticks: {
            stepSize: 20, // Adjust the step size between ticks
          },
        },

        y1: {
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
        },
      },
    },
  };

  const chartPaiements = {
    title: {
      text: "Statistique des paiements tout confondus",
    },
    xAxis: {
      categories: labelPaiement,
    },
    series: [
      {
        name: "Paiements",
        data: dataPointsPaiement,
        color: "rgb(192, 126, 75)",
        fillColor: "rgba(18, 177, 235, 0.527)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  const chartPaiementWeek = {
    title: {
      text: "Paiements Par semaine",
    },
    xAxis: {
      categories: labelPaiementWeek,
    },
    series: [
      {
        name: "Paiements Par semaine",
        data: dataPointsPaiementWeek,
        color: "rgb(215, 30, 89)",
        fillColor: "rgba(217, 28, 69, 0.5)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  const chartPaiementLastWeek = {
    title: {
      text: "Paiements 7 derniers jours",
    },
    xAxis: {
      categories: labelPaiemenLastWeek,
    },
    series: [
      {
        name: "Paiements 7 derniers jours",
        data: dataPointsPaiementLastWeek,
        color: "rgb(149, 75, 192)",
        fillColor: "rgb(75, 128, 192)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  const chartClients = {
    title: {
      text: "Nombre d'incriptions",
    },
    xAxis: {
      categories: labelClient,
    },
    series: [
      {
        name: "Nombre d'incriptions",
        data: dataPointsClient,
        color: "rgb(75, 192, 192)",
        fillColor: "rgb(75, 192, 192)",
      },
    ],
    plotOptions: {
      series: {
        fillOpacity: 0.3,
      },
    },
  };

  return (
    <TemplateHeaderContainter
      title={`Welcome ${currentUser.firstName}`}
      description=""
      id={currentUser.id}
    >
      <div id="paiement" className="mt-0 px-5 py-3 lg:m-2 rounded-lg shadow-md">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-90 ml-5">
              Statistiques des Paiements
            </h3>
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1  xl:grid-cols-2">
              <div
                id="paiementsStat"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartPaiementLastWeek}
                />
              </div>
              <div
                id="paiementsStat"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartPaiements}
                />
              </div>
              <div
                id="paiementsStat"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartClients}
                />
              </div>
              <div
                id="paiementsStat"
                className="mt-2 px-1 py-0 lg:m-1 rounded-lg shadow-md border-2 border-orange-500 "
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartPaiementWeek}
                />
              </div>
            </div>
            <div className="" />
            {/* <div className="mt-8 px-5 py-0 lg:m-1 rounded-lg shadow-md border-2 border-blue-500">
              <Line data={data} options={chartOptions} height={chartHeight} />
            </div> */}
          </>
        )}
      </div>
    </TemplateHeaderContainter>
  );
}
